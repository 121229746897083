import {
  starsAnimation,
  wordsAnimation,
  carouselAnimation,
  animeTicker,
  confettiAnimation,
  phoneWithCountryCode
} from "./helpers.js";

window.starsAnimation = starsAnimation;
window.wordsAnimation = wordsAnimation
window.carouselAnimation = carouselAnimation
window.animeTicker = animeTicker
window.confettiAnimation = confettiAnimation
window.phoneWithCountryCode = phoneWithCountryCode
