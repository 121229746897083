import { tsParticles } from "tsparticles-engine";
import { loadStarsPreset } from "tsparticles-preset-stars";
import anime from "animejs";
import $ from "jquery";
window.$ = window.jQuery = $;

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { parsePhoneNumber, AsYouType, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { dialCodesList } from '../quiz/country-codes.js'

import confetti from 'canvas-confetti';
import axios from 'axios';

export const phoneWithCountryCode = function () {
  return {
    selectedFlag: {
      name: "United States",
      dial_code: "+1",
      code: "US"
    },
    phone: '',
    placeholder: '',
    openSelect: false,
    listCountryCodes: Object.values(dialCodesList()),
    filteredCountryCodes: Object.values(dialCodesList()),
    searchCountry: '',
    processPhone() {
      try {
        const result = parsePhoneNumber(this.phone, this.selectedFlag.code)
        Livewire.dispatch('processPhone', { phone: result?.nationalNumber, code: this.selectedFlag.dial_code, isPossible: result?.isValid() });
        this.phone = new AsYouType(this.selectedFlag.code).input(this.phone)
      } catch (error) {
        Livewire.dispatch('processPhone', { phone: '', code: this.selectedFlag.dial_code, isPossible: false });
      }
    },
    filterCountryCodes() {
      if (this.searchCountry === '') {
        this.filteredCountryCodes = this.listCountryCodes
        return
      }
      this.filteredCountryCodes = this.listCountryCodes.filter(country => country.name.toLowerCase().includes(this.searchCountry.toLowerCase()))
    },
    initPhoneWithCountryCode() {
      axios.get('https://ipapi.co/json/').then(response => {
        const countryCode = response.data.country_code;
        const country = dialCodesList()[countryCode];
        if (country) {
          this.selectedFlag = country;
        }
        this.updatePlaceholder()
      })
    },
    updatePlaceholder() {
      try {
        const example = getExampleNumber(this.selectedFlag.code,  examples);
        this.placeholder = example
          ? example.formatNational().replace(/\d/g, 'X')
          : '(XXX) - XXX - XXXX';
      } catch (error) {
        this.placeholder = '(XXX) - XXX - XXXX';
      }
    }
  }
}

export const carouselAnimation = function () {
  return {
    startCarousel(images) {

      let carouselHTML = '';
      images.forEach((img) => {
        carouselHTML += `
          <div>
            <img src="${img}" alt="icon" class="w-[56px] h-[56px]" />
          </div>
        `;
      });

      $('#carousel').empty();
      $('#carousel').append(carouselHTML);

      if ($('#carousel').hasClass('slick-initialized')) {
        $('#carousel').slick('destroy');
      }

      const carousel = $('#carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        infinite: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 300,
        swipe: false,
        touchMove: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false,
      }).on('init', function (event, slick) {
        slick.slickNext();
      }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        slick.$slides.find('img').removeClass('is-active is-active2 is-active3');

        const nextSlideImg = $(slick.$slides[nextSlide]).find('img');
        nextSlideImg.addClass('is-active');

        const prevIndex = (nextSlide - 1 + slick.$slides.length) % slick.$slides.length;
        const nextIndex = (nextSlide + 1) % slick.$slides.length;
        const prevPrevIndex = (nextSlide - 2 + slick.$slides.length) % slick.$slides.length;
        const nextNextIndex = (nextSlide + 2) % slick.$slides.length;

        const prevSlideImg = $(slick.$slides[prevIndex]).find('img');
        const nextNextSlideImg = $(slick.$slides[nextIndex]).find('img');
        prevSlideImg.addClass('is-active2');
        nextNextSlideImg.addClass('is-active2');

        const prevPrevSlideImg = $(slick.$slides[prevPrevIndex]).find('img');
        const nextNextNextSlideImg = $(slick.$slides[nextNextIndex]).find('img');
        prevPrevSlideImg.addClass('is-active3');
        nextNextNextSlideImg.addClass('is-active3');
      });

      carousel.slick('slickNext');
    }
  }
}

export const starsAnimation = function () {
  return {
    async startStarsAnimation() {
      await loadStarsPreset(tsParticles);
      await tsParticles.load(this.$refs.particles.id, {
        particles: {
          number: {
            density: {
              enable: true,
              width: 1920,
              height: 1080
            },
            value: 200
          },
          opacity: {
            value: {
              min: 0.3,
              max: 1
            },
            animation: {
              enable: true,
              speed: 2,
              startValue: "random",
              destroy: "none"
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            enable: true,
            speed: 0.1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: true,
              rotateX: 600,
              rotateY: 600
            }
          },
          retina_detect: true,
          size: {
            value: 1
          },
          color: {
            value: "#5249ee"
          },
          shape: {
            type: "circle"
          }
        },
        fullScreen: {
          enable: false,
        }
      });
    }
  };
}

export const wordsAnimation = function () {
  return {
    lines: [],
    startWordsAnimation() {
      let totalDelay = 0;
      this.lines.forEach((line) => {
        if (line) {
          // Process the line and get all word spans
          const words = this._splitIntoSpans(line);
          this._animateLine(words, totalDelay);
          totalDelay += this._calculateLineDuration(line);
        }
      });
    },
    _animateLine(words, startDelay) {
      const wordDelay = 100;
      anime({
        targets: words,
        color: ['#9ca3af', '#111827'],
        easing: 'easeInOutCubic',
        delay: anime.stagger(wordDelay, { start: startDelay }),
        duration: 300,
        complete: () => {
          words.forEach(word => {
            word.classList.remove('text-gray-400');
            word.classList.add('text-gray-900');
          });
        },
      });
    },
    _splitIntoSpans(line) {
      // Process each child node in the line.
      // For text nodes (nodeType === 3), split text into words and wrap each in a span.
      // For element nodes (nodeType === 1), split their text content into words,
      // wrap each in a span, and add an extra "underline" class.
      let words = [];
      const nodes = Array.from(line.childNodes);
      line.innerHTML = ''; // Clear the line to rebuild it

      nodes.forEach(node => {
        if (node.nodeType === 3) { // TEXT_NODE
          node.textContent.split(/\s+/).forEach(word => {
            const span = document.createElement('span');
            span.textContent = word + ' ';
            span.classList.add('word', 'text-gray-400', 'font-medium');
            line.appendChild(span);
            words.push(span);
          });
        } else if (node.nodeType === 1) { // ELEMENT_NODE
          node.textContent.split(/\s+/).forEach(word => {
            const span = document.createElement('span');
            span.textContent =  ' ' + word;
            // Add an extra "underline" class for element nodes
            span.classList.add('word', 'text-gray-400', 'font-medium', 'underline');
            line.appendChild(span);
            words.push(span);
          });
        }
      });

      return words;
    },
    _calculateLineDuration(line) {
      const wordDelay = 100;
      const wordCount = line.textContent.trim().split(/\s+/).length;
      return wordCount * wordDelay + 300;
    }
  };
};


export const animeTicker = function () {
  return {
    startAnimation() {
      const container = document.getElementById('marquee-container');
      const list1 = document.getElementById('list1');
      const list2 = document.getElementById('list2');

      const totalHeight = list1.scrollHeight;
      list2.style.top = totalHeight + 'px';

      const items = Array.from(list1.querySelectorAll('.item'));
      if (!items.length) return;
      const itemHeights = items.map(item => item.offsetHeight);
      let currentIndex = 0;
      let currentY = 0;

      function scaleItems() {
        const containerRect = container.getBoundingClientRect();
        const containerTop = containerRect.top;
        const containerHeight = containerRect.height;
        const containerCenter = containerTop + (containerHeight / 4);

        const third1 = containerTop + containerHeight / 3;
        const third2 = containerTop + (2 * containerHeight) / 3;

        const allItems = [
          ...list1.querySelectorAll('.item'),
          ...list2.querySelectorAll('.item')
        ];

        allItems.forEach(item => {
          const itemRect = item.getBoundingClientRect();
          const itemCenter = itemRect.top + itemRect.height / 2;

          item.classList.remove('top-third', 'middle-third', 'bottom-third');

          if (itemCenter >= third2) {
            item.classList.add('bottom-third');
          } else if (itemCenter >= third1) {
            item.classList.add('middle-third');
          } else {
            item.classList.add('top-third');
          }

          const distance = Math.abs(itemCenter - containerCenter);
          const maxDistance = 70; // px
          const clampedDistance = Math.min(distance, maxDistance);
          const scale = 1 + (maxDistance - clampedDistance) / maxDistance * 0.2;

          item.style.transform = `scale(${scale})`;
        });
      }

      function step() {
        const height = itemHeights[currentIndex];
        let targetY = currentY - height;

        anime({
          targets: { y: currentY },
          y: targetY,
          duration: 500,
          easing: 'easeInOutQuad',
          update: anim => {
            const newY = anim.animatables[0].target.y;
            list1.style.transform = `translateY(${newY}px)`;
            list2.style.transform = `translateY(${newY}px)`;
            scaleItems();
          },
          complete: () => {
            currentY = targetY;
            currentIndex = (currentIndex + 1) % itemHeights.length;
            if (Math.abs(currentY) >= totalHeight) {
              currentY = 0;
              currentIndex = 0;
            }
          }
        });
      }

      step();
      setInterval(step, 1500);
    }
  }
}

export const confettiAnimation = function () {
  return {
    startConfettiAnimation() {
      const element = document.getElementById(this.$refs.particles.id);

      if (!element) {
        return;
      }
      const rect = element.getBoundingClientRect();

      const x = (rect.left + rect.width / 2) / window.innerWidth;
      const y = 0.5

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { x: x, y: y }
      });
    }
  }
}
